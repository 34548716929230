import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { HasStore, paginatedResult } from '@application';
import { Doctor, DoctorPatient, doctorPatientsParamSearch, doctorPrescriptionsParamSearch, doctorSearchParams, DoctorSpecialty, Establishment, Prescription } from '@domain';
import { DoctorRepository, EstablishmentRepository } from '@infrastructure';

export type doctorState = {
	doctors: paginatedResult<Doctor[] | null>;
	specialties: DoctorSpecialty[];
	doctor: Doctor | null;
	doctorPatients: paginatedResult<DoctorPatient[] | null>;
	prescriptions: paginatedResult<Prescription[] | null>;
	establishments: Establishment[];
};

@Injectable({
	providedIn: 'root',
})
export default class DoctorManager extends HasStore<doctorState> {
	constructor(
		private readonly doctorRepository: DoctorRepository,
		private readonly establishmentRepository: EstablishmentRepository,
	) {
		super(
			{
				doctors: {
					data: null,
					pagination: {
						total: 0,
						page: 1,
						limit: 10,
						nbTotalPage: 1,
					},
				},
				doctor: null,
				doctorPatients: {
					data: null,
					pagination: {
						total: 0,
						page: 1,
						limit: 10,
						nbTotalPage: 1,
					},
				},
				prescriptions: {
					data: null,
					pagination: {
						total: 0,
						page: 1,
						limit: 10,
						nbTotalPage: 1,
					},
				},
				specialties: [],
				establishments: [],
			},
			'doctor',
		);
	}

	searchDoctors(params: doctorSearchParams) {
		this.doctorRepository
			.searchDoctors(params)
			.pipe(take(1))
			.subscribe(doctors => {
				this.store.update((state: doctorState) => {
					return {
						...state,
						doctors,
					};
				});
			});
	}

	getDoctorByRpps(rpps: string) {
		this.doctorRepository
			.getDoctorByRpps(rpps)
			.pipe(take(1))
			.subscribe((doctor: Doctor) => {
				this.store.update((state: doctorState) => {
					return {
						...state,
						doctor,
					};
				});
			});
	}

	searchDoctorPatients(doctorPatientsParamSearch: doctorPatientsParamSearch) {
		const doctor = this.store.getValue().doctor;
		this.doctorRepository
			.searchDoctorPatients(doctor!.rpps, doctorPatientsParamSearch)
			.pipe(take(1))
			.subscribe(doctorPatients => {
				this.store.update((state: doctorState) => {
					return {
						...state,
						doctorPatients,
					};
				});
			});
	}

	getDoctorSpecialties() {
		return this.doctorRepository
			.getDoctorSpecialties()
			.pipe(take(1))
			.subscribe(specialties => {
				this.store.update(state => {
					return {
						...state,
						specialties,
					};
				});
			});
	}

	getDoctorPrescriptions(rpps: string, params: doctorPrescriptionsParamSearch) {
		return this.doctorRepository
			.getDoctorPrescriptions(rpps, params)
			.pipe(take(1))
			.subscribe(prescriptions => {
				this.store.update(state => {
					return {
						...state,
						prescriptions,
					};
				});
			});
	}

	getDoctorEstablishments(rpps: string) {
		return this.establishmentRepository
			.getDoctorEstablishments(rpps)
			.pipe(take(1))
			.subscribe(establishments => {
				this.store.update(state => {
					return {
						...state,
						establishments,
					};
				});
			});
	}

	resetDoctor() {
		this.store.update(state => {
			return {
				...state,
				doctor: null,
				doctorPatients: {
					data: null,
					pagination: { total: 0, page: 1, limit: 10, nbTotalPage: 1 },
				},
				prescriptions: {
					data: null,
					pagination: {
						total: 0,
						page: 1,
						limit: 10,
						nbTotalPage: 1,
					},
				},
			};
		});
	}
}
